* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .relevant-experience,
  .irrelevant-experience,
  .projects,
  .about-me {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  /* .relevant-experience {
    background-image: url('C:\Users\schot\portfolio-website\my-website\public\images\img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .irrelevant-experience {
    background-image: url('C:\Users\schot\portfolio-website\my-website\public\images\img-2.jpg');
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
  .projects {
    background-image: url('C:\Users\schot\portfolio-website\my-website\public\images\img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }

  .about-me {
    background-image: url('C:\Users\schot\portfolio-website\my-website\public\images\img-2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  } */