.footer-container {
  background-color: rgb(44, 44, 44);
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 5px groove rgb(22, 22, 22);
}

.header {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
  align-items: center;
}

.description {
  margin-top: 8px;
  color: #fff;
  font-size: 24px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  width: 400px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .header {
    font-size: 24px;
  }

  .description {
    font-size: 18px;
    padding: 40px;
  }
}
